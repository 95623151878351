import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InvoicingService } from '../../services/invoicing.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { debounceTime, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { FilterInvoicingSidebarComponent } from './filter-sidebar/filter-invoicing-sidebar-component';

export interface Invoicing {
  invoice: string;
  title: string;
  type: string;
  account: string;
  costCenter: string;
  month: string;
  amount: string;
  status: string;
  lastUpdated: string;
  action: string;
}

@Component({
  selector: 'app-invoicing',
  templateUrl: './invoicing.component.html',
})
export class InvoicingComponent implements OnInit {
  loading: boolean = false;
  invoicingList: any[] = [];
  totalRecords: number = 0;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  costModal: string = '';
  projectName: string[] = [];
  form!: FormGroup;
  element: any;
  invoiceID!: string;
  projectList: any[] = [];
  costCenterList: any[] = [];
  projectChecked: { [projectId: string]: boolean } = {};
  costCenterChecked: { [cost_centerID: string]: boolean } = {};
  statusChecked: { [status: string]: any } = {};
  filteredInvoices: any[] = [];
  invoiceDate: any;
  lastSortBy: string = '';
  canManageResources: boolean = false;
  isPaid: boolean = false;
  isFilterApplied: boolean = false;
  selectedDates: string[] = [];
  selectedProjects: string[] = [];
  selectedCostCenters: string[] = [];
  selectedStatus: string[] = [];
  showGeneratedOnly: string = 'true';

  @ViewChild('projectSelect') projectSelect!: MatSelect;
  @ViewChild('selectStatus') selectStatus!: MatSelect;
  @ViewChild('costCentreSelect') costCentreSelect!: MatSelect;

  constructor(
    private router: Router,
    private invoicingService: InvoicingService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  displayedInvoicingColumns: string[] = [
    'invoice',
    'title',
    'type',
    'account',
    'costCenter',
    'month',
    'amount',
    'status',
    'lastUpdated',
    'action',
  ];

  dataSource = new MatTableDataSource<Invoicing>([]);

  @ViewChild('filter') filterComponent!: FilterInvoicingSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    }
  }

  ngOnInit(): void {
    this.loadInvoicingPermissions();
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy,
      this.showGeneratedOnly
    );

    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchInvoicing();
  }

  loadInvoicingPermissions(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const invoicingPermissions = userPermissions.find(
        (p: any) => p.module === 'invoice'
      );
      if (
        invoicingPermissions &&
        invoicingPermissions.permission.includes('view') &&
        invoicingPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      } else {
        this.canManageResources = false;
      }
    } else {
      this.canManageResources = false;
    }
  }

  getInvoicingList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    showGeneratedOnly: string
  ): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceList(pageNumber, limit, search, sortBy, orderBy, showGeneratedOnly)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  retrieveDataForDateRange(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    invoiceDate: string
  ) {
    this.loading = true;
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        invoiceDate
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingListByProject(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[] = []
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        '',
        projectId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoiceListByCostCenter(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[] = []
  ): void {
    this.loading = true;
    const costCenterIds = selectedCostCenters.join('&cost_centerID=');
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        '',
        '',
        costCenterIds
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoiceListByStatus(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedStatus: string[] = []
  ): void {
    this.loading = true;
    const statusName = selectedStatus.join('&invoiceStatus=');
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        '',
        '',
        '',
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedDates: string[],
    selectedProjects: string[],
    selectedCostCenters: string[],
    selectStatus: string[]
  ): void {
    this.loading = true;
    const dates = selectedDates.join('&invoiceDate=');
    const projectId = selectedProjects.join('&projectId=');
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    const status = selectStatus.join('&invoiceStatus=');
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        dates,
        projectId,
        costCenterID,
        status
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.invoicingList = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Invoicing List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any) {
    this.selectedDates = filters.selectedDates || [];
    this.selectedProjects = filters.selectedProjects || [];
    this.selectedCostCenters = filters.selectedCostCenters || [];
    this.selectedStatus = filters.selectedStatus || [];
    if (this.selectedProjects.length > 0 && this.selectedCostCenters.length > 0) {
      this.getInvoicingListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedDates,
        this.selectedProjects,
        this.selectedCostCenters,
        this.selectedStatus
      );
    } else if (this.selectedDates.length > 0) {
      const selectedDatesString = this.selectedDates.join(','); 
      this.retrieveDataForDateRange(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedDatesString
      );
    } else if (this.selectedCostCenters.length > 0) {
      this.getInvoiceListByCostCenter(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedCostCenters
      );
    } else if (this.selectedProjects.length > 0) {
      this.getInvoicingListByProject(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects
      );
    } else if (this.selectedStatus.length > 0) {
      this.getInvoiceListByStatus(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedStatus
      );
    } else {
      this.getInvoicingList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.showGeneratedOnly
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';
    const dates = this.selectedDates.join('&invoiceDate=');
    const projectId = this.selectedProjects.join('&projectId=');
    const costCenterID = this.selectedCostCenters.join('&cost_centerID=');
    const status = this.selectedStatus.join('&invoiceStatus=');

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.invoicingService
      .getInvoiceList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        '',
        '',
        dates,
        projectId,
        costCenterID,
        status
      )
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.invoicingList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Invoice List request:', error);
        },
      });
  }

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }

  getFormattedStatus(status: string): string {
    if (status === 'draft') {
      return 'Draft';
    } else if (status === 'sent') {
      return 'Sent';
    } else if (status === 'void') {
      return 'Void';
    } else if (status === 'received') {
      return 'Received';
    } else {
      return status;
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  searchInvoicing(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getInvoicingList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.showGeneratedOnly
        );
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC',
      this.showGeneratedOnly
    );
  }

  onEditClick(invoiceID: string): void {
    this.router.navigate(['invoicing/edit', invoiceID]);
  }
}